import { organizationClient, OrganizationDetails } from "api/organization";
import { Dispatch } from "redux";
import { State } from "store";
import { global } from "store/global";
import { actions } from "./organization.actions";
import { router } from "../router";
import { OrganizationDetailsFormState } from "../../app/components/OrganizationDetailsForm/model";
import { OrganizationFormState } from "../../app/pages/Organizations/View/OrganizationForm/model";
import { Territory } from "api/geoZone";

const createOrganization = (form: OrganizationDetailsFormState) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(actions.createOrganization(form));

    const { type, details, logo, packageType, packagePerPallet } = form;
    const fixedDetails: OrganizationDetails = {
      ...details,
      name: details.name.trim(),
    };

    const updatedEvent = await organizationClient.createOrganization(
      type,
      fixedDetails,
      logo,
      packageType,
      packagePerPallet
    );
    await organizationClient.createApplication(updatedEvent.payload.id);

    const updated = await organizationClient.query.getOrganization(
      updatedEvent.payload.id
    );
    dispatch(actions.updatedOrganization(updated));
    await global.queries.fetchOrganizationRefs(true)(dispatch, getState);
  };
};

const updateOrganization = (
  organizationId: string,
  form: OrganizationFormState,
  updatePayment: boolean,
  updateExportAuthorization: boolean,
  updateVisitor: boolean
) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.updateOrganization(organizationId, form.details));

    const { type, details, logo, packageType, packagePerPallet } = form.details;
    const fixedDetails: OrganizationDetails = {
      ...details,
      name: details.name.trim(),
    };

    const updatedEvent = await organizationClient.updateOrganization(
      type,
      fixedDetails,
      organizationId,
      logo,
      packageType,
      packagePerPallet
    );

    if (updatePayment) {
      await organizationClient.updatePaymentInfo(organizationId, {
        iban: form.payment.iban || "",
        bic: form.payment.bic || "",
        tva: form.payment.tva || "",
      });
    }

    if (updateExportAuthorization) {
      await organizationClient.updateExportAuthorization(organizationId, {
        deliveryPeriod: form.exportAuthorization.canExportDelivery
          ? {
              startDate: form.exportAuthorization.deliveryPeriodStartMonth ?? 0,
              endDate: form.exportAuthorization.deliveryPeriodEndMonth ?? 0,
            }
          : null,
        tracePeriod: form.exportAuthorization.canExportTrace
          ? {
              startDate: form.exportAuthorization.tracePeriodStartMonth ?? 0,
              endDate: form.exportAuthorization.tracePeriodEndMonth ?? 0,
            }
          : null,
      });
    }

    if (updateVisitor) {
      await organizationClient.updateVisitorAuthorization(organizationId, {
        territoryCodes: form.visitor.territories.map(
          (territory: Territory) => territory.code
        ),
        visitorAuthorization: {
          roles: form.visitor.roles,
          watchedOperatorId: form.visitor.watchedOperatorId,
          watchedCarrierId: form.visitor.watchedCarrierId,
        },
      });
    }

    const updated = await organizationClient.query.getOrganization(
      updatedEvent.payload.id
    );
    dispatch(actions.updatedOrganization(updated));
  };
};

const deleteOrganization = (organizationId: string) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(actions.deleteOrganization(organizationId));
    await organizationClient.deleteOrganizationCommand(organizationId);
    dispatch(actions.deletedOrganization(organizationId));
    dispatch(router.goto.organization.organizationsList(0, 10));

    await global.queries.fetchOrganizationRefs(true)(dispatch, getState);
  };
};

const prepareSignConvention = (organizationId: string) => {
  return async () => {
    const redirectUrl = window.location.toString();
    const event = await organizationClient.prepareSignConvention(
      organizationId,
      redirectUrl
    );
    const docuSignUrl = event.payload.docuSignUrl;
    docuSignUrl && window.open(docuSignUrl, "_self");
  };
};

const resetConvention = (organizationId: string) => {
  return async () => {
    await organizationClient.resetConvention(organizationId);
  };
};

export const commands = {
  createOrganization,
  updateOrganization,
  deleteOrganization,
  prepareSignConvention: prepareSignConvention,
  resetConvention: resetConvention,
};
