import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import withConnect from "./withConnect";
import { Notification, notificationClient } from "api/notification";
import { NotificationList } from "components";
import { User } from "api/organization/users";
import { useTranslation } from "react-i18next";
import { getRelativeDate } from "utils";
import { NotificationData, notificationMapping } from "./NotificationData";

interface NotificationsContainerProps {
  currentUser: User | null;
  className?: string;
  id: string;
  pinned?: boolean;
  pageNumber: number;
  onPageNumberChange: (page: number) => void;
  goto: (url: string) => void;
}

const NotificationsContainer = forwardRef<
  HTMLDivElement,
  NotificationsContainerProps
>((props, ref) => {
  const {
    currentUser,
    className,
    id,
    pinned = false,
    pageNumber,
    onPageNumberChange,
    goto,
  } = props;
  const [notifications, setNotifications] = useState<NotificationData[]>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const observer = useRef<IntersectionObserver>();
  useEffect(() => {
    if (!loading) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && total > pageNumber * 10 && !loading) {
          onPageNumberChange(pageNumber + 1);
        }
      });
      const element = document.getElementById(id);
      element && observer.current.observe(element);
    }
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, total, pageNumber]);

  useEffect(() => {
    currentUser && setLoading(true);
    currentUser &&
      notificationClient.query
        .getNotificationPage(
          currentUser.details.organizationId,
          pageNumber - 1,
          10,
          pinned
        )
        .then((notificationPage) => {
          if (notificationPage) {
            if (notificationPage.total !== total) {
              setTotal(notificationPage.total);
            }
            setNotifications((prevNotifications) => {
              if (prevNotifications && pageNumber !== 1) {
                return [...prevNotifications].concat(
                  notificationPage.list.map((notification: Notification) => {
                    return toNotificationData(notification);
                  })
                );
              } else {
                return notificationPage.list.map(
                  (notification: Notification) => {
                    return toNotificationData(notification);
                  }
                );
              }
            });
          }
          setLoading(false);
        });
  }, [currentUser, pageNumber, pinned]);

  const onPinned = useCallback(
    (notif: NotificationData, index: number) => {
      notificationClient.pinNotification(notif.id).then((event) => {
        setNotifications((prevNotifications) => {
          if (prevNotifications) {
            let notificationCopy = [...prevNotifications];
            notificationCopy[index].pinned = true;
            return notificationCopy;
          }
          return prevNotifications;
        });
      });
    },
    [notifications]
  );

  const onUnPinned = useCallback(
    (notif: NotificationData, index: number) => {
      notificationClient.unpinNotification(notif.id).then((event) => {
        setNotifications((prevNotifications) => {
          if (prevNotifications) {
            let notificationCopy = [...prevNotifications];
            if (pinned) {
              notificationCopy.splice(index, 1);
              setTotal((prevTotal) => prevTotal - 1);
            } else {
              notificationCopy[index].pinned = false;
            }
            return notificationCopy;
          }
          return prevNotifications;
        });
      });
    },
    [pinned, notifications]
  );

  const onRemove = useCallback((notif: NotificationData, index: number) => {
    notificationClient.archiveNotification(notif.id).then((event) => {
      setNotifications((prevNotifications) => {
        if (prevNotifications) {
          let notificationCopy = [...prevNotifications];
          notificationCopy.splice(index, 1);
          setTotal((prevTotal) => prevTotal - 1);
          return notificationCopy;
        }
        return prevNotifications;
      });
    });
  }, []);

  const onClick = useCallback((notif: NotificationData) => {
    notif.url && goto(notif.url);
  }, []);

  const getLabel = (notif: NotificationData): React.ReactNode => {
    return !!notif.messageKey ? (
      <>
        {t(notif.messageKey + "_1", { ...notif.messageParams })}
        {labelExist(notif, 2) && (
          <span style={{ textDecoration: "underline", color: "#007DCE" }}>
            {t(notif.messageKey + "_2", { ...notif.messageParams })}
          </span>
        )}
        {labelExist(notif, 3) && (
          t(notif.messageKey + "_3", { ...notif.messageParams })
        )}
      </>
    ) : (
      notif.messageDefault
    );
  };

  const labelExist = (notif: NotificationData, labelNumber: number): boolean => {
    return !t(notif.messageKey + "_" + labelNumber, { ...notif.messageParams }).includes(notif.messageKey!!)
  }

  const shouldBeDisplayed = (notif: NotificationData): boolean => {
    return !!currentUser && notif.canUserSee(currentUser);
  };

  const toNotificationData = (notification: Notification): NotificationData => {
    const notif = notificationMapping.get(notification);
    notif.creationDate = getRelativeDate(notif.creationDate, t);
    notif.label = getLabel(notif);
    notif.show = shouldBeDisplayed(notif);
    return notif;
  };

  return (
    <NotificationList
      ref={ref}
      notificationClassName={className}
      lastElementId={id}
      loading={loading}
      notifications={notifications ? notifications : []}
      onPageNumberChange={(page) => onPageNumberChange(page)}
      onPinnedNotif={onPinned}
      onUnPinnedNotif={onUnPinned}
      onRemoveNotif={onRemove}
      onClickNotif={onClick}
      pageNumber={pageNumber}
      total={total}
    />
  );
});

export default withConnect(NotificationsContainer);
