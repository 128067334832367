import {buildProjectApiUrl} from "api/configuration";
import { http } from "../../http";


export const exportProjectsStats = async (): Promise<string | null> => {
  return http.fetchDownloadableFile(
    buildProjectApiUrl(`/projectStats/export`),
    "Une erreur est survenue lors de l'export des campagnes",
    "post",
  );
};
