import { makeStyles } from "@material-ui/core";
import { User } from "api/organization/users";
import { projectClient } from "api/project";
import { ProjectStats } from "api/project/model";
import { ProjectStatusUtils } from "api/project/model/ProjectStatus";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import { usePaginedList } from "app/hooks/usePaginedList/usePaginedList";
import { useAuth } from "auth/useAuth";
import {
  Button,
  ProjectFilters,
  ProjectRow,
  ProjectTable,
  Section,
  ProjectFiltersModel,
  DownloadButton,
} from "components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { nullToUndefined } from "utils";
import withConnect from "./withConnect";
const useStyles = makeStyles(() => ({
  button: {
    float: "right",
    marginRight: "30px",
    height: "49.5px !important",
  },
}));

interface ProjectsProps {
  gotoProjectCreate: () => void;
  projectGeneralView: (projectId: string) => void;
  gotoProjectsList: (
    page: number,
    size: number,
    name?: string,
    status?: string
  ) => void;
  resetProject: () => void;
  currentUser: User | null;
}

const Projects = (props: ProjectsProps) => {
  const {
    projectGeneralView,
    gotoProjectCreate,
    gotoProjectsList,
    resetProject,
    currentUser,
  } = props;
  const classes = useStyles();
  const authResult = new URLSearchParams(window.location.search);
  const [filterValues, setFilterValues] = useState<ProjectFiltersModel>({
    name: nullToUndefined(authResult.get("projectName")),
    status: nullToUndefined(authResult.get("projectStatus")),
  });
  const [authService, keycloak] = useAuth();

  const isColisActivAdmin = useMemo(() => authService.isColisActivAdmin(currentUser, keycloak),
   [authService, currentUser, keycloak])

  const fetchProjectsPage = useCallback(
    (page, size) => {
      return projectClient.query.getProjectsPage(
        page,
        size,
        filterValues.name,
        filterValues.status
      );
    },
    [filterValues]
  );

  const gotoProjectsListCallback = useCallback(
    (page, size) =>
      gotoProjectsList(page, size, filterValues.name, filterValues.status),
    [gotoProjectsList, filterValues.name, filterValues.status]
  );

  const [items, isLoading, handlePageChange] = usePaginedList<ProjectStats>(
    [],
    fetchProjectsPage,
    gotoProjectsListCallback,
    [fetchProjectsPage]
  );

  const onProjectFiltersChange = useCallback(
    (values: ProjectFiltersModel) => {
      gotoProjectsList(0, items.perPage, values.name, values.status);
      setFilterValues(values);
    },
    [items.perPage, gotoProjectsList, setFilterValues]
  );

  const handleProjectsExport = useCallback(async () => {
    const objectUrl = await projectClient.query.exportProjectsStats();
    if (objectUrl == null) {
      return;
    }
    const currentDate = new Date().toLocaleDateString();

    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `Export des projets au ${currentDate}.csv`;
    a.click();
  }, [])

  const { t } = useTranslation();
  return (
    <PageWithTitle
      headBar={{ title: `${t("app_project_list_projects")} ` }}
      header={
        <>
          <ProjectFilters
            filterValues={filterValues}
            onFilterChange={onProjectFiltersChange}
          />
          {authService.isColisActivAdmin(currentUser, keycloak) && (
            <Button
              className={classes.button}
              onClick={() => {
                resetProject();
                gotoProjectCreate();
              }}
            >
              {t("app_project_list_new_projects")}
            </Button>
          )}
          {isColisActivAdmin && (
            <DownloadButton className={classes.button} onClick={() => handleProjectsExport()} 
            label={t("app_campaign_details_form_action_export")}/>
          )}
        </>
      }
      columnSwitchWidth={800}
      switchedHeaderHeight={150}
    >
      <Section actions={[]}>
        <ProjectTable
          projects={items.list}
          isLoading={isLoading}
          totalPages={Math.ceil(items.totalRows / items.perPage)}
          page={items.page + 1}
          onRowClicked={(row: ProjectRow) => projectGeneralView(row.id)}
          handlePageChange={handlePageChange}
          getActions={(row: ProjectStats) => [
            {
              key: row.id,
              label: "Voir",
              goto: () => projectGeneralView(row.id),
            },
          ]}
          getStatus={ProjectStatusUtils.getStatusFromRow}
        />
      </Section>
    </PageWithTitle>
  );
};

export default withConnect(Projects);
