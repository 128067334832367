import React, { Fragment, useCallback, useMemo, useState } from "react";
import withConnect from "./withConnect";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import {
  Button,
  CampaignDeliveryFilters,
  CampaignDeliveryFiltersModel,
  CampaignDeliveryRow,
  CampaignDeliveryTable,
  DeliveryTour,
  HeadlineBox,
  InputForm,
  Option,
  PopUpConfirmation,
  SelectedItem,
  SelectedItemsWithActions,
} from "components";
import { OrganizationRef } from "api/organization";
import { usePaginedList } from "app/hooks/usePaginedList/usePaginedList";
import { getDefaultOrganizationLogo } from "app/components/OrganizationDetailsForm/OrganizationLogo";
import { formatDate, nullToUndefined, nullToUndefinedNumber } from "utils";
import { geoZoneClient } from "api/geoZone";
import { campaignDeliveryClient } from "api/campaignDelivery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  button: {
    height: "44px",
    margin: "10px",
  },
  headline1: {
    width: "50%",
  },
  headline2: {
    width: "45%",
  },
  pageSizeSelect: {
    width: "100px",
    marginTop: "-60px",
    marginLeft: "20px",
  },
}));

interface SubmittedState {
  deliveries: DeliveryTour[];
  bonusIds: number[];
}

interface UrlParams {
  defaultPagesize?: string,
  start?: number,
  end?: number,
  operatorId?: string,
  areaName?: string,
  areaId?: string,
  areaCode?: string,
  minPackage?: string,
  maxPackage?: string,
  minBikeCarDistanceRate?: string,
  maxBikeCarDistanceRate?: string
}

interface CampaignDeliveryProps {
  organizationRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  gotoCampaignDelivery: (
    page?: number,
    size?: number,
    startDate?: number,
    endDate?: number,
    operatorId?: string,
    areaName?: string,
    areaId?: string,
    areaCode?: string,
    minPackage?: number,
    maxPackage?: number,
    minBikeCarDistanceRate?: number,
    maxBikeCarDistanceRate?: number
  ) => void;
  gotoOrganizationView: (organizationId: string) => void;
  gotoBonusView: (bonusId: string) => void;
}

const CampaignDelivery = (props: CampaignDeliveryProps) => {
  const {
    organizationRefs,
    operatorRefs,
    gotoCampaignDelivery,
    gotoOrganizationView,
    gotoBonusView,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [checkedDeliveries, setCheckedDeliveries] = useState<
    (SelectedItem & DeliveryTour)[]
  >([]);
  const [submittedDeliveries, setSubmittedDeliveries] =
    useState<SubmittedState>({ bonusIds: [], deliveries: [] });
  const [openValidation, setOpenValidation] = useState<boolean>(false);
  const [openRejection, setOpenRejection] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState(false);
  const urlParams: UrlParams = useMemo(() => {
    const authResult = new URLSearchParams(window.location.search);
    return {
      defaultPagesize: nullToUndefined(authResult.get("size")),
      start: formatDate(authResult.get("startDate")),
      end: formatDate(authResult.get("endDate")),
      operatorId: nullToUndefined(authResult.get("operatorId")),
      areaName: nullToUndefined(authResult.get("areaName")),
      areaId: nullToUndefined(authResult.get("areaId")),
      areaCode: nullToUndefined(authResult.get("areaCode")),
      minPackage: nullToUndefined(authResult.get("minPackage")),
      maxPackage: nullToUndefined(authResult.get("maxPackage")),
      minBikeCarDistanceRate: nullToUndefined(authResult.get("minBikeCarDistanceRate")),
      maxBikeCarDistanceRate: nullToUndefined(authResult.get("maxBikeCarDistanceRate")),
    }
  }, [window.location.search]);
  const [pageSize, setPageSize] = useState<number>(
    urlParams.defaultPagesize ? +urlParams.defaultPagesize : 100
  );

  const [filterValues, setFiltersValue] =
    useState<CampaignDeliveryFiltersModel>({
      period:
      urlParams.start && urlParams.end
          ? {
              startDate: urlParams.start,
              endDate: urlParams.end,
            }
          : undefined,
      operatorId: urlParams.operatorId,
      area:
      urlParams.areaName && urlParams.areaId && urlParams.areaCode
          ? {
              code: urlParams.areaCode,
              id: urlParams.areaId,
              name: urlParams.areaName,
            }
          : undefined,
      minPackage: urlParams.minPackage ? +urlParams.minPackage : undefined,
      maxPackage: urlParams.maxPackage ? +urlParams.maxPackage : undefined,
      minBikeCarDistanceRate: urlParams.minBikeCarDistanceRate ? +urlParams.minBikeCarDistanceRate : undefined,
      maxBikeCarDistanceRate: urlParams.maxBikeCarDistanceRate ? +urlParams.maxBikeCarDistanceRate : undefined,
    });

  const getOperators = useCallback((): Option[] => {
    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);

  const onFiltersChange = (values: CampaignDeliveryFiltersModel) => {
    setProcessing(true)
    gotoCampaignDelivery(
      0,
      items.perPage,
      nullToUndefinedNumber(values.period?.startDate),
      nullToUndefinedNumber(values.period?.endDate),
      values.operatorId,
      values.area?.name,
      values.area?.id,
      values.area?.code,
      values.minPackage,
      values.maxPackage,
      values.minBikeCarDistanceRate,
      values.maxBikeCarDistanceRate
    );
    setFiltersValue({
      period: values.period,
      operatorId: values.operatorId,
      area: values.area,
      minPackage: values.minPackage,
      maxPackage: values.maxPackage,
      minBikeCarDistanceRate: values.minBikeCarDistanceRate,
      maxBikeCarDistanceRate: values.maxBikeCarDistanceRate,
    });
  };

  const fetchPage = useCallback(
    async (page, size) => {
      return campaignDeliveryClient.query.getRejectedDeliveryTours(
        page,
        size,
        filterValues.operatorId,
        filterValues.period,
        filterValues.area?.code,
        filterValues.minPackage,
        filterValues.maxPackage,
        filterValues.minBikeCarDistanceRate,
        filterValues.maxBikeCarDistanceRate,
        false
      ).then((tours) => {
        setProcessing(false)
        return tours
      });
    },
    [filterValues]
  );

  const gotoListCallback = useCallback(
    (page, size) =>
      gotoCampaignDelivery(
        page,
        size,
        filterValues.period?.startDate,
        filterValues.period?.endDate,
        filterValues.operatorId,
        filterValues.area?.name,
        filterValues.area?.id,
        filterValues.area?.code,
        filterValues.minPackage,
        filterValues.maxPackage,
        filterValues.minBikeCarDistanceRate,
        filterValues.maxBikeCarDistanceRate
      ),
    [gotoCampaignDelivery, filterValues]
  );

  const [items, isLoading, handlePageChange, fetchItems] =
    usePaginedList<DeliveryTour>([], fetchPage, gotoListCallback, [fetchPage]);

  const selectDeselectDelivery = (
    checked: boolean,
    row: CampaignDeliveryRow
  ) => {
    if (checked) {
      const delivery = items.list.find(
        (delivery) => delivery.tourId === row.tourId
      );
      if (delivery == null) return;

      const checkedDelivery: SelectedItem & DeliveryTour = {
        ...delivery,
        id: String(delivery.tourId),
        informations: (
          <Box display="flex" justifyContent="space-between">
            <HeadlineBox className={classes.headline1} header={row.operator}>
              {row.date}
            </HeadlineBox>
            <HeadlineBox className={classes.headline2} header={row.area} />
          </Box>
        ),
      };

      setCheckedDeliveries((checkedDeliveries) => [
        ...checkedDeliveries,
        checkedDelivery,
      ]);
    } else {
      unCheckDelivery(String(row.tourId));
    }
  };

  const unCheckDelivery = (tourId: string) => {
    const filteredDeliveries = checkedDeliveries.filter(
      (it) => it.id != tourId
    );
    setCheckedDeliveries(filteredDeliveries);
  };

  const onChangeGlobalCheck = (globalChecked: boolean) => {
    if (!globalChecked) {
      let checkDeliveriesCopy = [...checkedDeliveries];
      const currentItemsIds = items.list.map((delivery) => delivery.tourId);
      checkDeliveriesCopy = checkDeliveriesCopy.filter(
        (el) => !currentItemsIds.includes(el.tourId)
      );
      setCheckedDeliveries(checkDeliveriesCopy);
      return;
    }
    const checkedDeliveriesIds = checkedDeliveries.map((el) => el.tourId);
    const deliveriesToCheck = items.list.filter(
      (el) => !checkedDeliveriesIds.includes(el.tourId)
    );
    const newDeliveriesChecked: (SelectedItem & DeliveryTour)[] =
      deliveriesToCheck.map((delivery) => {
        const operator = operatorRefs.get(delivery.operatorId);
        return {
          ...delivery,
          id: String(delivery.tourId),
          informations: (
            <Box display="flex" justifyContent="space-between">
              <HeadlineBox
                header={operator?.displayName}
                className={classes.headline1}
              >
                {new Date(delivery.date).toLocaleDateString()}
              </HeadlineBox>
              <HeadlineBox
                header={delivery.area.name}
                className={classes.headline2}
              />
            </Box>
          ),
        };
      });
    setCheckedDeliveries([...checkedDeliveries, ...newDeliveriesChecked]);
  };

  const onSubmitValidation = useCallback(
    (submittedDeliveries: DeliveryTour[]) => {
      let bonusIds: number[] = [];
      submittedDeliveries.forEach((delivery) => {
        delivery.bonusIds.forEach((bonusId) => {
          if (!bonusIds.includes(bonusId)) {
            bonusIds.push(bonusId);
          }
        });
      });
      setSubmittedDeliveries({
        deliveries: submittedDeliveries,
        bonusIds: bonusIds,
      });
      setOpenValidation(true);
    },
    []
  );

  const onSubmitRejection = useCallback(
    (submittedDeliveries: DeliveryTour[]) => {
      setSubmittedDeliveries({
        deliveries: submittedDeliveries,
        bonusIds: [],
      });
      setOpenRejection(true);
    },
    []
  );

  const handleDecision = useCallback(
    async (applyDecision: (tourIds: string[]) => Promise<any>) => {
      const tourIds = submittedDeliveries.deliveries.map(
        (delivery) => delivery.tourId
      );
      await applyDecision(tourIds);

      setSubmittedDeliveries({ bonusIds: [], deliveries: [] });
      setCheckedDeliveries((checkedDeliveries) => {
        return checkedDeliveries.filter(
          (delivery) => !tourIds.includes(delivery.id)
        );
      });

      fetchItems();
    },
    [submittedDeliveries, fetchItems]
  );

  const onValidate = useCallback(async () => {
    setOpenValidation(false);
    setProcessing(true);
    await handleDecision(
      campaignDeliveryClient.command.queueForceAcceptDeliveryTours
    );
    setProcessing(false);
  }, [handleDecision]);

  const onReject = useCallback(async () => {
    setOpenRejection(false);
    setProcessing(true);
    await handleDecision(campaignDeliveryClient.command.rejectDeliveryTours);
    setProcessing(false);
  }, [handleDecision]);

  const validatePage = useCallback(async () => {
    await campaignDeliveryClient.command.queueForceAcceptDeliveryTours(
      items.list.map((item) => item.tourId)
    );
    fetchItems();
  }, [items, fetchItems]);

  const handlePageSizeChange = useCallback(
    (size) => {
      setPageSize(size);
      gotoListCallback(0, size);
      items.perPage = size;
      fetchItems();
    },
    [gotoListCallback, fetchItems]
  );

  const pageSizeSelectOptions = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];

  return (
    <PageWithTitle
      headBar={{ title: "Livraisons" }}
      header={
        <Fragment>
          <CampaignDeliveryFilters
            operators={getOperators()}
            onFilterChange={onFiltersChange}
            getGeoZones={geoZoneClient.getGeoZones}
            filterValues={filterValues}
          />
          <Button onClick={validatePage}>
            {t("app_domain_campaign_delivery_validate_page")}
          </Button>
        </Fragment>
      }
      columnSwitchWidth={800}
      switchedHeaderHeight={150}
    >
      <CampaignDeliveryTable
        campaignDeliveries={items.list}
        checkedDeliveries={checkedDeliveries.map((delivery) => delivery.tourId)}
        getDefaultOrganizationLogo={getDefaultOrganizationLogo}
        isLoading={isLoading || isProcessing}
        page={items.page + 1}
        totalPages={Math.ceil(items.totalRows / items.perPage)}
        handlePageChange={handlePageChange}
        organizationsRef={organizationRefs}
        onChangeGlobalCheck={onChangeGlobalCheck}
        onClickCarrier={gotoOrganizationView}
        onClickCheckBox={selectDeselectDelivery}
        onValidateDelivery={(row: CampaignDeliveryRow) =>
          onSubmitValidation([row.delivery])
        }
        onRejectDelivery={(row: CampaignDeliveryRow) =>
          onSubmitRejection([row.delivery])
        }
      />
      <InputForm
        inputType={"select"}
        label={""}
        placeHolder={""}
        className={`${classes.pageSizeSelect}`}
        onChange={handlePageSizeChange}
        selectOptions={pageSizeSelectOptions}
        value={pageSize}
        id="CampaignDelivery-filters-page-size"
      />
      {checkedDeliveries.length > 0 && (
        <SelectedItemsWithActions
          displayList={false}
          items={checkedDeliveries}
          onRemoveItem={unCheckDelivery}
          actions={
            <Box display="flex" alignItems="center">
              <Button
                fail
                variant="outlined"
                onClick={() => onSubmitRejection(checkedDeliveries)}
                isLoading={isProcessing}
                className={classes.button}
              >
                {t("app_domain_campaign_delivery_reject")}
              </Button>
              <Button
                onClick={() => onSubmitValidation(checkedDeliveries)}
                isLoading={isProcessing}
                className={classes.button}
              >
                {t("app_domain_campaign_delivery_validate")}
              </Button>
            </Box>
          }
        />
      )}
      <PopUpConfirmation
        open={openValidation}
        onConfirm={onValidate}
        onClose={() => setOpenValidation(false)}
      >
        <Typography>
          {t("app_domain_campaign_delivery_validate_warning_1")}
          {submittedDeliveries.bonusIds.map((bonusId, index) => (
            <span
              key={`linkToBonus-${bonusId}`}
              style={{ textDecoration: "underline", color: "#007DCE" }}
              onClick={() => gotoBonusView(String(bonusId))}
            >{`prime n°${bonusId}${
              index !== submittedDeliveries.bonusIds.length - 1 ? ", " : "."
            }`}</span>
          ))}
          {t("app_domain_campaign_delivery_validate_warning_2")}
        </Typography>
      </PopUpConfirmation>
      <PopUpConfirmation
        open={openRejection}
        onConfirm={onReject}
        onClose={() => setOpenRejection(false)}
      >
        <Typography>
          {t("app_domain_campaign_delivery_reject_warning", {
            adj: submittedDeliveries.deliveries.length > 1 ? "ces" : "ce",
            s: submittedDeliveries.deliveries.length > 1 ? "s" : "",
          })}
        </Typography>
      </PopUpConfirmation>
    </PageWithTitle>
  );
};

export default withConnect(CampaignDelivery);
