import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { themeContext } from '@smartb/archetypes-ui-components'
import React, { useContext } from 'react'
import {
  DeliveryBoxIcon,
  HourGlassIcon,
  Pay,
  Pickup,
  ShipmentDelivered,
  TruckIcon,
  GeoZoneIcon,
  BikeIcon,
  CloudIcon
} from '../../Icons'
import { HeadlineBox } from '../HeadlineBox'
import { InfoBox } from '../InfoBox'
import { Panel } from '../Panel'
import { GasStationIcon } from '../../Icons/GasStationIcon'
import { VisitorRole, VisitorRoleValues } from 'web-admin/src/api/organization'

const useStyles = makeStyles(() => ({
  gridRoot: {
    maxWidth: '800px',
    minWidth: '600px',
    marginBottom: '20px'
  },
  icon: {
    width: '30px',
    height: '30px',
    marginBottom: '20px'
  },
  text: {
    '& p': {
      overflowX: 'auto!important',
      textOverflow: 'clip!important',
    },
    '& .colisactivHeadlineBox-header': {
      marginBottom: '5px'
    }
  },
  panel: {
    width: '100%',
    height: '100%',
    '& .ColisactivPanel-content': {
      padding: '15px !important'
    }
  },
  infoIcon: {
    width: '20px',
    height: '20px',
    margin: '5px',
    marginBottom: '-2px'
  },
  internText: {
    fontSize: '12px',
    color: '#828282'
  }
}))

interface DashboardGridProps {
  activePackCount: string
  unwrappedPalletsPackCount: string
  manualPackCount: string
  autoPackCount: string
  hubsCount: number
  bonusTotal: string
  bonusToDeduce: string
  bonusToReport: string
  bonusPaid: string
  bonusCreated: string
  bonusDeduced: string
  bonusInvalid: string
  deliveryTime: string
  manualDeliveryTime: string
  autoDeliveryTime: string
  activeDeliveries: string
  bonusPerPackage: string
  tourAmount: string
  averageDistancePerDeliveryWalkBikeAverage: string
  distanceTotalWalkBikeAverage: string
  averageApproachDurationBike: string
  averageApproachDistanceWalkBike: string
  averageApproachDistancePerDeliveryWalkBike: string
  thermalKmAvoided: string
  co2TonsAvoided: string
  noxTonsAvoided: string
  ppmTonsAvoided: string
  isAdmin: boolean
  visitorRoles?: Nullable<string[]>
}

export const DashboardGrid = (props: DashboardGridProps) => {
  const {
    activePackCount,
    unwrappedPalletsPackCount,
    autoPackCount,
    hubsCount,
    bonusTotal,
    bonusToDeduce,
    bonusToReport,
    bonusPaid,
    bonusDeduced,
    bonusCreated,
    bonusInvalid,
    deliveryTime,
    autoDeliveryTime,
    activeDeliveries,
    bonusPerPackage,
    tourAmount,
    averageDistancePerDeliveryWalkBikeAverage,
    distanceTotalWalkBikeAverage,
    averageApproachDurationBike,
    averageApproachDistanceWalkBike,
    averageApproachDistancePerDeliveryWalkBike,
    thermalKmAvoided,
    totalThermalKmAvoided,
    co2TonsAvoided,
    noxTonsAvoided,
    ppmTonsAvoided,
    fuelAvoided,
    isAdmin,
    visitorRoles = null
  } = props
  const classes = useStyles()
  const theme = useContext(themeContext)

  const isNotVisitorOrHasRole = (role: VisitorRole) =>
    !visitorRoles || visitorRoles?.includes(role.role)

  return (
    <div>
      <Box display='flex' justifyContent='space-around' flexWrap='wrap'>
        <Grid container spacing={3} className={classes.gridRoot}>
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_BONUS_TOTAL
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <Pay className={classes.icon} color={theme.primaryColor} />
                <Box display='flex' alignItems='flex-end'>
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={`${bonusTotal} €`}
                  >
                    Total de la prime
                  </HeadlineBox>
                  <InfoBox
                    className={classes.infoIcon}
                    content={
                      <Box display='flex' flexDirection='column'>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusToDeduce} € à déduire`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusToReport} € à reporter`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusDeduced} € déduit`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusPaid} € payé`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusCreated} € en construction`}
                        </Typography>
                        <Typography
                          variant='body2'
                          className={classes.internText}
                        >
                          {`${bonusInvalid} € en incident`}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_BONUS_PER_PACKAGE
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <Pay className={classes.icon} color={theme.primaryColor} />
                <HeadlineBox
                  className={classes.text}
                  size='big'
                  header={`${bonusPerPackage} €`}
                >
                  Prime par colis
                </HeadlineBox>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_DELIVERY_TIME
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <HourGlassIcon
                  className={classes.icon}
                  color={theme.primaryColor}
                />
                <Box display='flex' alignItems='flex-end'>
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={deliveryTime}
                  >
                    Temps total de livraison
                  </HeadlineBox>
                  {isAdmin && (
                    <InfoBox
                      className={classes.infoIcon}
                      content={
                        <Box display='flex' flexDirection='column'>
                          <Typography
                            variant='body2'
                            className={classes.internText}
                          >
                            {`dont transmis ${autoDeliveryTime} via API`}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                </Box>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_ACTIVE_DELIVERIES
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <ShipmentDelivered
                  className={classes.icon}
                  color={theme.primaryColor}
                />
                <HeadlineBox
                  className={classes.text}
                  size='big'
                  header={activeDeliveries}
                >
                  Nombre de livraisons actives
                </HeadlineBox>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_ACTIVE_PACK_COUNT
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <DeliveryBoxIcon
                  className={classes.icon}
                  color={theme.primaryColor}
                />
                <Box display='flex' alignItems='flex-end'>
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={activePackCount}
                  >
                    Colis et palettes
                  </HeadlineBox>
                  {isAdmin && (
                    <InfoBox
                      className={classes.infoIcon}
                      content={
                        <Box display='flex' flexDirection='column'>
                          <Typography
                            variant='body2'
                            className={classes.internText}
                          >
                            {`dont ${autoPackCount} colis transmis via API`}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                </Box>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_ACTIVE_PACK_COUNT
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <DeliveryBoxIcon
                  className={classes.icon}
                  color={theme.primaryColor}
                />
                <Box display='flex' alignItems='flex-end'>
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={unwrappedPalletsPackCount}
                  >
                    Équivalent colis
                  </HeadlineBox>
                </Box>
              </Panel>
            </Grid>
          )}
          {isNotVisitorOrHasRole(
            VisitorRoleValues.READ_DASHBOARD_HUB_COUNT
          ) && (
            <Grid item xs={4}>
              <Panel className={classes.panel}>
                <Pickup className={classes.icon} color={theme.primaryColor} />
                <HeadlineBox
                  className={classes.text}
                  size='big'
                  header={hubsCount}
                >
                  Nombre de HUB
                </HeadlineBox>
              </Panel>
            </Grid>
          )}
          {(isAdmin || visitorRoles) && (
            <>
            {isNotVisitorOrHasRole(
              VisitorRoleValues.READ_DASHBOARD_AVERAGE_DISTANCE_PER_DELIVERY
            ) && (
              <Grid item xs={4}>
                <Panel className={classes.panel}>
                  <BikeIcon
                    className={classes.icon}
                    color={theme.primaryColor}
                  />
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={`${averageApproachDurationBike}`}
                  >
                    Durée d'approche moyenne
                  </HeadlineBox>
                </Panel>
              </Grid>
            )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_AVERAGE_DISTANCE_PER_DELIVERY
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <BikeIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${averageApproachDistanceWalkBike} m`}
                    >
                      Distance d'approche moyenne
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_AVERAGE_DISTANCE_PER_DELIVERY
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <BikeIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${averageApproachDistancePerDeliveryWalkBike} m`}
                    >
                      Distance d'approche moyenne par point
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_AVERAGE_DISTANCE_PER_DELIVERY
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <BikeIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${averageDistancePerDeliveryWalkBikeAverage} m`}
                    >
                      Mètres moyen point à point
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_TOUR_AMOUNT
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <DeliveryBoxIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${tourAmount}`}
                    >
                      Nombre de tournées
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_DISTANCE_TOTAL
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <GeoZoneIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${distanceTotalWalkBikeAverage} km`}
                    >
                      Distance totale vélo parcourue
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_THERMAL_KM_AVOIDED
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <TruckIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <HeadlineBox
                      className={classes.text}
                      size='big'
                      header={`${thermalKmAvoided} km / ${totalThermalKmAvoided} km`}
                    >
                      Km vul thermique évité (en tournée / total)
                    </HeadlineBox>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_CO2_AVOIDED
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <CloudIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <Box display='flex' alignItems='flex-end'>
                      <HeadlineBox
                        className={classes.text}
                        size='big'
                        header={`${co2TonsAvoided} tonnes`}
                      >
                        Tonnes de CO2 évitées
                      </HeadlineBox>
                      <InfoBox
                        className={classes.infoIcon}
                        content={
                          <Box display='flex' flexDirection='column'>
                            <Typography
                              variant='body2'
                              className={classes.internText}
                            >
                              {`calculs basés sur l'étude du CEREMA du 20 avril 2021 “Émissions routières des polluants atmosphériques Courbes et facteurs d’influence” réalisée en utilisant la méthodologie Copert 5 en considérant les émissions d’un parc roulant composé à 90% de véhicules utilitaires légers (part des livraisons confiées par des expressistes) et à 10% de poids lourd (part des livraisons confiées par des messagers) à la vitesse moyenne de 12km/h représentative de celles constatées en logistique urbaine en zone dense.`}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_NOX_PPM_AVOIDED
              ) && (
                <Grid item xs={4}>
                  <Panel className={classes.panel}>
                    <CloudIcon
                      className={classes.icon}
                      color={theme.primaryColor}
                    />
                    <Box display='flex' alignItems='flex-end'>
                      <HeadlineBox
                        className={classes.text}
                        size='big'
                        header={`${noxTonsAvoided} tonnes / ${ppmTonsAvoided} kg`}
                      >
                        NOX / PM<sub>10</sub> évitées
                      </HeadlineBox>
                      <InfoBox
                        className={classes.infoIcon}
                        content={
                          <Box display='flex' flexDirection='column'>
                            <Typography
                              variant='body2'
                              className={classes.internText}
                            >
                              {`calculs basés sur l'étude du CEREMA du 20 avril 2021 “Émissions routières des polluants atmosphériques Courbes et facteurs d’influence” réalisée en utilisant la méthodologie Copert 5 en considérant les émissions d’un parc roulant composé à 90% de véhicules utilitaires légers (part des livraisons confiées par des expressistes) et à 10% de poids lourd (part des livraisons confiées par des messagers) à la vitesse moyenne de 12km/h représentative de celles constatées en logistique urbaine en zone dense.`}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Panel>
                </Grid>
              )}
              {isNotVisitorOrHasRole(
                VisitorRoleValues.READ_DASHBOARD_NOX_PPM_AVOIDED
              ) && (
              <Grid item xs={4}>
                <Panel className={classes.panel}>
                  <GasStationIcon
                    className={classes.icon}
                    color={theme.primaryColor}
                  />
                  <HeadlineBox
                    className={classes.text}
                    size='big'
                    header={`${fuelAvoided} L`}
                  >
                    Volume de carburant évité
                  </HeadlineBox>
                </Panel>
              </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </div>
  )
}
