import {
  createOrganizationCommand,
  OrganizationCreatedEvent,
} from "./command/createOrganizationCommand";
import { OrganizationDetails, OrganizationType, PackageType } from "./index";
import {
  OrganizationUpdatedEvent,
  updateOrganizationCommand,
} from "./command/updateOrganizationCommand";
import { logoCreateCommand } from "./command/logoCreateCommand";

export const createOrganization = async (
  type: OrganizationType,
  details: OrganizationDetails,
  logo?: LogoType,
  packageType?: PackageType,
  packagePerPallet?: number
): Promise<OrganizationCreatedEvent> => {
  const createEvent = await createOrganizationCommand(
    type,
    details,
    packageType,
    packagePerPallet
  );
  const organizationId = createEvent.payload.id;
  await handleLogo(organizationId, logo);
  return createEvent;
};

export const updateOrganization = async (
  type: OrganizationType,
  details: OrganizationDetails,
  organizationId: string,
  logo?: LogoType,
  packageType?: PackageType,
  packagePerPallet?: number
): Promise<OrganizationUpdatedEvent> => {
  const updatedEvent = await updateOrganizationCommand(
    type,
    details,
    organizationId,
    packageType,
    packagePerPallet
  );
  await handleLogo(organizationId, logo);
  return updatedEvent;
};

export const handleLogo = async (organizationId: string, logo?: LogoType) => {
  if (!!logo?.file) await logoCreateCommand(organizationId, logo.file);
};

export type LogoType = {
  file?: File;
  src?: string;
};
