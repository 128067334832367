import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  DeleteButton,
  EditButton,
  PopUpConfirmation,
  Section,
  Option,
  HubTable,
  MenuItemAction,
} from "components";
import {
  Hub,
  HubId,
  Organization,
  OrganizationRef,
  OrganizationStateMachine,
  organizationClient,
} from "api/organization";
import OrganizationForm from "app/components/OrganizationDetailsForm";
import withConnect from "./withConnect";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { routesAccessRights } from "auth/RoutesAccessRightsMap";
import { useTranslation } from "react-i18next";
import { PaymentInfoForm } from "../../../../components/PaymentInfoForm";
import { OrganizationDetailsFormState } from "../../../../components/OrganizationDetailsForm/model";
import { PaymentInfoFormState } from "../../../../components/PaymentInfoForm/model";
import {
  buildOrganizationForm,
  defaultOrganizationFormState,
  OrganizationFormState,
} from "./model";
import { isOrganizationFormValid } from "./validation";
import usePrevious from "app/hooks/usePrevious/usePrevious";
import { DeliveryExportAuthorizationForm } from "app/components/ExportAuthorizationForm";
import { ExportAuthorizationFormState } from "app/components/ExportAuthorizationForm/model";
import { VisitorForm } from "app/components/VisitorForm";
import { VisitorFormState } from "app/components/VisitorForm/model";
import { HubForm } from "app/components/HubForm";
import { AddHubCommandDTO } from "api/organization/command/addHubCommand";
import { UpdateHubActivityEndDateCommandDTO } from "api/organization/command/updateHubActivityEndDateCommand";
import { HubPopup, HubPopupField, HubPopupState } from "components/src/Domain/Organization";
import { UpdateHubCommentCommandDTO } from "api/organization/command/updateHubCommentCommand";


const useStyles = makeStyles(() => ({
  button: {
    position: "relative",
    marginLeft: 50,
  },
  title: {
    borderBottom: "2px solid #98a5ab33",
    padding: "20px 0",
    marginBottom: "30px",
  },
  text: {
    color: "#353945",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
  },
  complementarySection: {
    marginTop: 30,
  },
}));

interface GeneralInformationsProps {
  organizationRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  organization: Organization | null;
  gotoOrganizationEditGeneralInformations: (organizationId: string) => void;
  gotoOrganizationEditPaymentInformations: (organizationId: string) => void;
  gotoOrganizationEditExportAuthorizationInformations: (
    organizationId: string
  ) => void;
  gotoOrganizationEditVisitorInformations: (organizationId: string) => void;
  gotoOrganizationEditHubInformations: (organizationId: string) => void;
  gotoOrganizationsView: (organizationId: string) => void;
  updateOrganization: (
    organizationId: string,
    form: OrganizationFormState,
    updatePayment: boolean,
    exportAuthorization: boolean,
    visitorAuthorization: boolean
  ) => void;
  deleteOrganization: (organizationId: string) => void;
  isOrganizationAdmin: boolean;
  isColisActivAdmin: boolean;
  fetchOrganization: () => void;
}

const GeneralInformations = (props: GeneralInformationsProps) => {
  const {
    organizationRefs,
    carrierRefs,
    operatorRefs,
    organization,
    gotoOrganizationsView,
    updateOrganization,
    deleteOrganization,
    gotoOrganizationEditGeneralInformations,
    gotoOrganizationEditPaymentInformations,
    gotoOrganizationEditExportAuthorizationInformations,
    gotoOrganizationEditVisitorInformations,
    gotoOrganizationEditHubInformations,
    isOrganizationAdmin,
    isColisActivAdmin,
    fetchOrganization
  } = props;
  const classes = useStyles();
  const { mode } = useParams();
  const { t } = useTranslation();
  const isViewMode = mode === "view";

  const [organizationForm, setOrganizationForm] =
    useState<OrganizationFormState>(defaultOrganizationFormState);

  const [authService, keycloak] = useAuth();
  const [deleteConfirmation, openDeleteConfirmation] = useState(false);
  const [edit, setEdit] = useState({
    generalInfos: false,
    paymentInfos: false,
    exportAuthorization: false,
    visitorInfos: false,
    hubInfos: false,
  });

  const [hasPaymentChanged, setHasPaymentChanged] = useState(false);
  const [hasExportAuthorizationChanged, setHasExportAuthorizationChanged] =
    useState(false);
  const [hasVisitorChanged, setHasVisitorChanged] = useState(false);
  const [hubPopupState, setHubPopupState] = useState<HubPopupState>({hub: undefined, field: undefined});

  const previousMode = usePrevious(mode);

  useEffect(() => {
    if (
      previousMode !== undefined &&
      !isViewMode &&
      previousMode !== "view" &&
      organization
    ) {
      const organizationForm = buildOrganizationForm(organization);
      setOrganizationForm(organizationForm);
    }

    if (!isViewMode) {
      setEdit({
        generalInfos: mode === "editGeneralInformations",
        paymentInfos: mode == "editPaymentInformations",
        exportAuthorization: mode == "editExportAuthorizationInformations",
        visitorInfos: mode == "editVisitorInformations",
        hubInfos: mode == "editHubInformations",
      });
    }
  }, [mode]);

  useEffect(() => {
    if (organization) {
      const organizationForm = buildOrganizationForm(organization);
      setOrganizationForm(organizationForm);
    }
  }, [organization]);

  const showPaymentForm = useMemo((): boolean => {
    return (
      !!organization &&
      organization.organizationType.type === "Operator" &&
      authService.isAuthorized(routesAccessRights["/bonus"], keycloak)
    );
  }, [organization, authService, keycloak]);

  const showExportForm = useMemo((): boolean => {
    return (
      !!organization &&
      (organization.organizationType.type === "Funder" ||
        organization.organizationType.type === "FunderCee" ||
        organization.organizationType.type === "Visitor") &&
      isColisActivAdmin
    );
  }, [organization, isColisActivAdmin]);

  const showVisitorForm = useMemo((): boolean => {
    return (
      !!organization &&
      organization.organizationType.type === "Visitor" &&
      isColisActivAdmin
    );
  }, [organization, isColisActivAdmin]);

  const showHubForm = useMemo((): boolean => {
    return (
      organization != null && (
      organization.organizationType.type === "Operator" ||
      organization.organizationType.type === "Carrier") &&
      isColisActivAdmin
    );
  }, [organization, isColisActivAdmin]);

  const isOperatorOrg = useMemo((): boolean => {
    return (
      organization != null && organization.organizationType.type === "Operator"
    );
  }, [organization]);

  const setDetails = useCallback(
    (details: OrganizationDetailsFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        details: details,
      });
    },
    [setOrganizationForm, organizationForm]
  );

  const onSaveOrganization = useCallback(async () => {
    if (!organization) return;
    if (edit.generalInfos) {
      const organizationValidity = isOrganizationFormValid(
        false,
        false,
        false,
        true,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          details: {
            ...organizationForm.details,
            validity: organizationValidity.details,
          },
        });
        return;
      }
    } else if (edit.paymentInfos) {
      const organizationValidity = isOrganizationFormValid(
        true,
        false,
        false,
        false,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          payment: {
            ...organizationForm.payment,
            validity: organizationValidity.payment,
          },
        });
        return;
      }
    } else if (edit.exportAuthorization) {
      const organizationValidity = isOrganizationFormValid(
        false,
        true,
        false,
        false,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          exportAuthorization: {
            ...organizationForm.exportAuthorization,
            validity: organizationValidity.exportAuthorization,
          },
        });
        return;
      }
    } else if (edit.visitorInfos) {
      const organizationValidity = isOrganizationFormValid(
        false,
        false,
        true,
        false,
        organizationForm
      );
      if (!organizationValidity.isValid) {
        setOrganizationForm({
          ...organizationForm,
          visitor: {
            ...organizationForm.visitor,
            validity: organizationValidity.visitor,
          },
        });
        return;
      }
    }
    updateOrganization(
      organization.id,
      organizationForm,
      hasPaymentChanged,
      hasExportAuthorizationChanged,
      hasVisitorChanged
    );
    gotoOrganizationsView(organization.id);
  }, [
    edit,
    organizationForm,
    organization,
    hasPaymentChanged,
    hasExportAuthorizationChanged,
    hasVisitorChanged,
    isOrganizationFormValid,
    updateOrganization,
    gotoOrganizationsView,
  ]);

  const actions = useMemo(() => {
    const actionReturn = [];
    if (!organization) return [];
    if (isViewMode || !edit.generalInfos) {
      if (isViewMode && isOrganizationAdmin) {
        actionReturn.push(
          <EditButton
            key={"organization-update-button"}
            className={classes.button}
            onClick={() => {
              gotoOrganizationEditGeneralInformations(organization?.id);
            }}
            label={t("app_organization_action_update")}
          />
        );
        if (
          OrganizationStateMachine.getAvailableActions(
            organization.status.value
          ).canDelete
        ) {
          actionReturn.push(
            <>
              <PopUpConfirmation
                title={t("app_organization_action_delete_confirm")}
                onClose={() => openDeleteConfirmation(false)}
                onConfirm={() => deleteOrganization(organization.id)}
                open={deleteConfirmation}
              />
              <DeleteButton
                style={{ marginLeft: "15px" }}
                onClick={() => openDeleteConfirmation(true)}
              >
                {t("app_organization_action_remove")}
              </DeleteButton>
            </>
          );
        }
      }
    } else {
      actionReturn.push(
        <Button key={"organization-validate-button"} className={classes.button} onClick={onSaveOrganization}>
          {t("app_organization_action_validate")}
        </Button>
      );
    }
    return actionReturn;
  }, [
    organization,
    deleteConfirmation,
    isViewMode,
    edit.generalInfos,
    onSaveOrganization,
    openDeleteConfirmation,
    deleteOrganization,
    openDeleteConfirmation,
    gotoOrganizationEditGeneralInformations,
    isOrganizationAdmin,
    isColisActivAdmin,
  ]);

  const setPayment = useCallback(
    (payment: PaymentInfoFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        payment: payment,
      });
      setHasPaymentChanged(true);
    },
    [organizationForm, setOrganizationForm]
  );

  const setExportAuthorization = useCallback(
    (exportAuthorization: ExportAuthorizationFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        exportAuthorization: exportAuthorization,
      });
      setHasExportAuthorizationChanged(true);
    },
    [organizationForm, setOrganizationForm]
  );

  const getOperators = useCallback((): Option[] => {
    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);

  const getCarriers = useCallback((): Option[] => {
    return Array.from(carrierRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [organizationRefs]);

  const setVisitor = useCallback(
    (visitorFormState: VisitorFormState): void => {
      setOrganizationForm({
        ...organizationForm,
        visitor: visitorFormState,
      });
      setHasVisitorChanged(true);
    },
    [organizationForm, setOrganizationForm]
  );

  const addHub = useCallback(
    (command: AddHubCommandDTO): void => {
      organizationClient.addHub(organization!!.id, command).then(() => {
        fetchOrganization();
      })
    },
    [organizationClient.addHub, fetchOrganization]
  );

  const updateHubActivityEndDate = useCallback(
    (hubId: string, activityEndDate: Nullable<number>): void => {
      const command: UpdateHubActivityEndDateCommandDTO = {
        organizationId: organization!!.id.toString(), 
        hubId: hubId, 
        activityEndDate: activityEndDate
      }
      organizationClient.updateHubActivityEndDate(command).then(() => {
        fetchOrganization()
      })
    },
    [organizationClient.updateHubActivityEndDate, fetchOrganization]
  );

  const updateHubComment = useCallback(
    (hubId: string, comment: string): void => {
      const command: UpdateHubCommentCommandDTO = {
        organizationId: organization!!.id.toString(), 
        hubId: hubId, 
        comment: comment
      }
      organizationClient.updateHubComment(command).then(() => {
        fetchOrganization()
      })
    },
    [organizationClient.updateHubComment, fetchOrganization]
  );

  const removeHub = useCallback(
    (hubId: HubId): void => {
      organizationClient.removeHub(organization!!.id, hubId).then(() => {
        fetchOrganization()
      })
    },
    [organizationClient.addHub, fetchOrganization]
  );

  const hubActions = (hub: Hub): MenuItemAction[] => {
    return [
      {
        key: "updateHubActivityEndDate",
        label: hub.activityEndDate === null ? "Désactiver" : "Activer",
        goto: () => hub.activityEndDate === null ? setHubPopupState({hub: hub, field: HubPopupField.DATE}) : updateHubActivityEndDate(hub.id),
      },
      {
        key: "updateHubComment",
        label: "Modifier le commentaire",
        goto: () => setHubPopupState({hub: hub, field: HubPopupField.COMMENT}),
      },
      {
        key: "remove",
        label: "Supprimer",
        goto: () => removeHub(hub.id)
      },
    ]
  }

  if (organization == null) {
    return <div />;
  }

  return (
    <Section actions={[]}>
      <div className={classes.title}>
        <span className={classes.text}>
          {t("app_organizations_profil_users_general_info")}
        </span>
        {actions}
      </div>
      <OrganizationForm
        organization={organizationForm.details}
        onOrganizationChange={isViewMode ? () => {} : setDetails}
        readonly={isViewMode || !edit.generalInfos}
        edition={edit.generalInfos}
        isColisActivAdmin={isColisActivAdmin}
      />
      {showPaymentForm && (
        <>
          <div className={`${classes.title} ${classes.complementarySection}`}>
            <span className={classes.text}>
              {" "}
              {t("app_organizations_profil_users_payment_info")}{" "}
            </span>
            {(isViewMode || !edit.paymentInfos) && isOrganizationAdmin && (
              <EditButton
                key={"organization-edit-payment-button"}
                className={classes.button}
                onClick={() => {
                  gotoOrganizationEditPaymentInformations(organization?.id);
                }}
                label={t("app_organization_action_update")}
              />
            )}
            {!(isViewMode || !edit.paymentInfos) && isOrganizationAdmin && (
              <Button
                key={"organization-validate-button"}
                className={classes.button}
                onClick={onSaveOrganization}
              >
                {t("app_organization_action_validate")}
              </Button>
            )}
          </div>
          <PaymentInfoForm
            onDetailsChange={
              isViewMode || !edit.paymentInfos ? () => {} : setPayment
            }
            payment={organizationForm.payment}
            readonly={isViewMode || !edit.paymentInfos}
          />
        </>
      )}
      {showVisitorForm && (
        <>
          <div className={`${classes.title} ${classes.complementarySection}`}>
            <span className={classes.text}>
              {" "}
              {t("app_organizations_visitor")}{" "}
            </span>
            {(isViewMode || !edit.visitorInfos) && isColisActivAdmin && (
              <EditButton
                key={"organization-edit-visitor-button"}
                className={classes.button}
                onClick={() => {
                  gotoOrganizationEditVisitorInformations(organization?.id);
                }}
                label={t("app_organization_action_update")}
              />
            )}
            {!(isViewMode || !edit.visitorInfos) && isColisActivAdmin && (
              <Button
                key={"organization-validate-button"}
                className={classes.button}
                onClick={onSaveOrganization}
              >
                {t("app_organization_action_validate")}
              </Button>
            )}
          </div>
          <VisitorForm
            onDetailsChange={
              isViewMode || !edit.visitorInfos ? () => {} : setVisitor
            }
            visitorFormState={organizationForm.visitor}
            operators={getOperators()}
            carriers={getCarriers()}
            readonly={isViewMode || !edit.visitorInfos}
          />
        </>
      )}
      {showHubForm && (
        <>
          <div className={`${classes.title} ${classes.complementarySection}`}>
            <span className={classes.text}>
              {" "}
              {isOperatorOrg ? t("app_organizations_operator_hub") : t("app_organizations_carrier_hub")}
              {" "}
            </span>
            {(isViewMode || !edit.hubInfos) && (
              <EditButton
                key={0}
                className={classes.button}
                onClick={() => {
                  gotoOrganizationEditHubInformations(organization?.id);
                }}
                label={isOperatorOrg ? t("app_organizations_operator_hub_add") : t("app_organizations_carrier_hub_add")}
              />
            )}
            {!(isViewMode || !edit.hubInfos) && (
              <EditButton
                key={0}
                className={classes.button}
                onClick={() => gotoOrganizationsView(organization.id)}
                label={t("app_organization_action_close")}
              />
            )}
          </div>
          {!(isViewMode || !edit.hubInfos) && (
            <HubForm
              addHub={addHub}
              readonly={isViewMode}
            />
          )}
          <div className={`${classes.title} ${classes.complementarySection}`}>
            <span className={classes.text}>
              {" "}{isOperatorOrg ? t("app_organizations_operator_hub_list") : t("app_organizations_carrier_hub_list")}{" "}
            </span>
          </div>
          <HubTable 
            hubs={organization?.hubs ?? []}
            getActions={hubActions}
          />
          <HubPopup
            hub={hubPopupState.hub}
            field={hubPopupState.field}
            onSubmit={(hub: Hub) => {
              switch (hubPopupState.field) {
                case HubPopupField.DATE:
                  updateHubActivityEndDate(hub.id, hub.activityEndDate)
                  break;
                case HubPopupField.COMMENT:
                  updateHubComment(hub.id, hub.comment)
                  break;
                default:
                  break;
              }
              setHubPopupState({hub:undefined, field:undefined})
            }}
            onCancel={() => setHubPopupState({hub:undefined, field:undefined})}
          />
        
        
        </>
      )}
      {showExportForm && (
        <>
          <div className={`${classes.title} ${classes.complementarySection}`}>
            <span className={classes.text}>
              {" "}
              {t("app_organizations_delivery_export")}{" "}
            </span>
            {(isViewMode || !edit.exportAuthorization) && isColisActivAdmin && (
              <EditButton
                key={"organization-edit-export-authorization-button"}
                className={classes.button}
                onClick={() => {
                  gotoOrganizationEditExportAuthorizationInformations(
                    organization?.id
                  );
                }}
                label={t("app_organization_action_update")}
              />
            )}
            {!(isViewMode || !edit.exportAuthorization) && isColisActivAdmin && (
              <Button
                key={"organization-validate-button"}
                className={classes.button}
                onClick={onSaveOrganization}
              >
                {t("app_organization_action_validate")}
              </Button>
            )}
          </div>
          <DeliveryExportAuthorizationForm
            onDetailsChange={
              isViewMode || !edit.exportAuthorization
                ? () => {}
                : setExportAuthorization
            }
            exportAuthorization={organizationForm.exportAuthorization}
            readonly={isViewMode || !edit.exportAuthorization}
          />
        </>
      )}
    </Section>
  );
};

export default withConnect(GeneralInformations);
